.manage-questions-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #555;
  margin-bottom: 20px;
}

.manage-questions-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

select,
textarea,
input[type="text"],
input[type="file"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  transition: 0.3s;
}

select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="file"]:focus {
  border-color: #007bff;
}

textarea {
  resize: none;
  height: 100px;
}

.option-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-option-btn,
.submit-btn {
  padding: 10px 15px;
  font-size: 14px;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.add-option-btn:hover,
.submit-btn:hover {
  background: #0056b3;
}

@media screen and (max-width: 768px) {
  .manage-questions-container {
    padding: 15px;
  }

  select,
  textarea,
  input[type="text"] {
    font-size: 12px;
  }

  .submit-btn {
    font-size: 13px;
  }
}

/* Image preview styles */
.image-preview {
  width: 227px;  /* 6cm */
  height: 149px; /* 4cm */
  object-fit: contain; /* Ensures the aspect ratio is maintained */
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}

input[type="file"] {
  margin-top: 10px;
}
