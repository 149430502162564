.edit-questions-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #555;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-bar input[type="text"] {
  width: 75%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  transition: 0.3s;
}

.search-bar input[type="text"]:focus {
  border-color: #007bff;
}

.search-bar button {
  padding: 10px 15px;
  font-size: 14px;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.search-bar button:hover {
  background: #0056b3;
}

.questions-list {
  margin-bottom: 20px;
}

.question-item {
  padding: 12px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: 0.3s;
}

.question-item:hover {
  background: #f1f1f1;
}

.edit-question-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

textarea,
input[type="text"],
input[type="file"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  transition: 0.3s;
}

textarea:focus,
input[type="text"]:focus,
input[type="file"]:focus {
  border-color: #007bff;
}

textarea {
  resize: none;
  height: 100px;
}

.option-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

button[type="submit"],
button[type="button"] {
  padding: 10px 15px;
  font-size: 14px;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

button[type="submit"]:hover,
button[type="button"]:hover {
  background: #0056b3;
}

button[type="button"] {
  background: #dc3545;
}

button[type="button"]:hover {
  background: #c82333;
}

/* Delete Question Button */
.delete-question-btn {
  padding: 10px 15px;
  font-size: 14px;
  color: #fff;
  background: #dc3545;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 15px;
}

.delete-question-btn:hover {
  background: #c82333;
}

/* Image preview size */
.image-preview {
  width: 227px;  /* 6cm */
  height: 149px; /* 4cm */
  object-fit: contain; /* Ensures the aspect ratio is maintained */
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .edit-questions-container {
    padding: 15px;
  }

  .search-bar input[type="text"] {
    font-size: 12px;
  }

  button[type="submit"],
  button[type="button"],
  .delete-question-btn {
    font-size: 13px;
  }

  .question-item {
    font-size: 13px;
  }
}
