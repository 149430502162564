/* Full-page styling */
.user-profile-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f4f7fc; /* Grey background */
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Profile Header */
.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.25rem; /* 20px */
}

/* Profile Image */
.profile-image {
  width: 150px; /* Updated size */
  height: 150px; /* Updated size */
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #4caf50;
  margin-bottom: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the image */
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  display: block; /* Ensure it fills the container */
}

/* Zoom effect on hover */
.profile-image:hover img {
  transform: scale(1.1);
}

/* Profile Info */
.profile-info {
  text-align: center;
}

.name {
  font-size: 1.5rem; /* 24px */
  font-weight: bold;
}

.email {
  color: gray;
  margin-top: 0.3125rem; /* 5px */
}

/* Profile Details */
.profile-details {
  width: 100%;
  max-width: 31.25rem; /* 500px */
  text-align: left;
  margin-top: 1.25rem; /* 20px */
}

.detail-item {
  display: flex;
  justify-content: space-between;
  margin: 0.625rem 0; /* 10px */
}

.label {
  font-weight: bold;
  color: #333;
}

.value {
  color: #555;
}

/* Social Share Section */
.social-share {
  margin-top: 1.875rem; /* 30px */
  text-align: center;
}

.social-share h3 {
  font-size: 1.25rem; /* 20px */
  margin-bottom: 0.9375rem; /* 15px */
  color: #333;
}

.share-icons {
  display: flex;
  justify-content: center;
  gap: 1.25rem; /* 20px */
}

.share-icon {
  font-size: 2rem; /* 32px */
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.share-icon:hover {
  transform: scale(1.2);
}

.whatsapp {
  color: #25d366; /* WhatsApp green */
}

.facebook {
  color: #1877f2; /* Facebook blue */
}

.telegram {
  color: #0088cc; /* Telegram blue */
}

.instagram {
  color: #e1306c; /* Instagram pink */
}

/* User Rank Card */
.rank-card {
  background-color: navy;
  color: white;
  border-radius: 10px;
  padding: 1.25rem; /* 20px */
  margin-top: 1.25rem; /* 20px */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem; /* 15px */
}

.rank-card h3 {
  font-size: 1.5rem; /* 24px */
  margin-bottom: 0.625rem; /* 10px */
}

.rank-card .gold-medal-icon {
  font-size: 1.875rem; /* 30px */
  color: gold;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem; /* 24px */
  font-weight: bold;
  z-index: 20;
}

/* Default (PC Screen) */
@media (min-width: 1200px) {
  .rank-card {
    width: 25%;
  }

  .profile-details {
    max-width: 500px;
    text-align: left;
  }
}

/* Tablet (iPad) Screen */
@media (min-width: 768px) and (max-width: 1199px) {
  .rank-card {
    width: 60%;
  }

  .profile-details {
    max-width: 450px;
  }

  .share-icons {
    gap: 1rem; /* Slightly smaller gap on tablet */
  }

  .share-icon {
    font-size: 1.8rem; /* Slightly smaller icons for tablet */
  }
}

/* Mobile (Small Screen) */
@media (max-width: 767px) {
  .rank-card {
    width: 90%;
  }

  .profile-details {
    width: 90%;
    text-align: center;
  }

  .share-icons {
    gap: 0.8rem; /* Smaller gap on mobile */
  }

  .share-icon {
    font-size: 1.6rem; /* Smaller icons for mobile */
  }

  .profile-info h2 {
    font-size: 1.25rem; /* Smaller name font on mobile */
  }
}
