.quiz-result-page {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.quiz-header {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
  text-align: center;
  margin-bottom: 20px;
}

.quiz-logo {
  height: 60px;
}

.quiz-header h1 {
  font-size: 24px;
  color: #333;
}

.quiz-summary {
  display: flex;
  justify-content: space-between; /* Distribute cards evenly */
  gap: 10px; /* Add spacing between cards */
  margin-bottom: 30px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.summary-card {
  flex: 1 1 30%; /* Allow each card to take up 30% of the width */
  padding: 20px;
  border-radius: 10px;
  color: white;
  text-align: center;
  min-width: 150px; /* Prevent cards from getting too small */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.summary-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.summary-card.correct {
  background-color: #4caf50;
}

.summary-card.incorrect {
  background-color: #f44336;
}

.summary-card.skipped {
  background-color: #ff9800;
}

.quiz-review {
  margin-top: 20px;
}

.question-card {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative; /* For positioning the bookmark icon */
}

.bookmark-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #f39c12;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.2s, color 0.2s;
}

.bookmark-icon:hover {
  transform: scale(1.2);
}

.bookmark-icon:disabled {
  color: #95a5a6;
  cursor: not-allowed;
}

.review-question-image {
  max-width: 100%;
  margin: 10px 0;
}

.options {
  margin-top: 10px;
}

.option {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  position: relative;
  transition: background-color 0.3s;
}

.option:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.option.correct {
  background-color: #e8f5e9;
  border: 1px solid #4caf50;
}

.option.correct::before {
  content: "✔";
  color: #4caf50;
  font-weight: bold;
  margin-right: 10px;
}

.option.selected {
  background-color: #fbe9e7;
  border: 1px solid #f44336;
}

.option.selected::before {
  content: "✘";
  color: #f44336;
  font-weight: bold;
  margin-right: 10px;
}

.option.skipped {
  background-color: #fff3e0;
  border: 1px solid #ff9800;
}

.badge {
  font-size: 12px;
  color: white;
  background-color: #000;
  border-radius: 3px;
  padding: 2px 5px;
  margin-left: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.quiz-footer {
  text-align: center;
  margin-top: 20px;
}

.back-button {
  background-color: #3f51b5;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.back-button:hover {
  background-color: #303f9f;
  transform: scale(1.05);
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .quiz-summary {
    flex-direction: row; /* Ensure horizontal alignment of the cards */
    justify-content: space-between; /* Space between the cards */
  }

  .summary-card {
    flex: 1 1 15%; /* Each card will take 30% of the width */
    min-width: 60px; /* Prevent cards from getting too small */
    margin-bottom: 10px; /* Small gap between cards */
    font-size: 1rem;
  }

  .question-card {
    padding: 10px;
  }

  .back-button {
    width: 100%;
    padding: 10px;
  }
}

/* Loading and Quiz Complete Screens */
.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
}

/* Rotating Circle */
.rotating-circle {
  width: 40px;
  height: 40px;
  border: 4px solid transparent;
  border-top: 4px solid #2c918e; /* The rotating color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-top: 20px; /* Space below the text */
}

/* Rotating Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
