/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f4f8;
}

/* Login Page Styles */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
}

.login-container {
  background-color: white;
  padding: 30px 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.login-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.login-container p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 30px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-group {
  width: 100%;
}

.input-field {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
}

.input-field:focus {
  border-color: #2575fc;
  box-shadow: 0 0 5px rgba(37, 117, 252, 0.3);
}

.login-btn {
  background-color: #2575fc;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-btn:hover {
  background-color: #6a11cb;
  transform: scale(1.05);
}

.register-prompt {
  margin-top: 20px;
  font-size: 1rem;
  color: #555;
}

.register-link {
  color: #2575fc;
  cursor: pointer;
  text-decoration: underline;
}

.register-link:hover {
  color: #6a11cb;
}
.admin-login-btn {
  background-color: #4CAF50; /* Example color */
  color: white;
  padding: 10px 20px;
  border: none;
  margin-top: 10px;
  cursor: pointer;
}

.admin-login-btn:hover {
  background-color: #45a049;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  text-align: center; /* Center-align the text content */
  padding: 20px; /* Add padding for smaller screens */
  box-sizing: border-box; /* Ensure padding doesn't affect dimensions */
}

.overlay h1 {
  font-family: 'Comic Sans MS', cursive; /* Apply Comic Sans MS font */
  font-size: 5vw; /* Use relative font size for responsiveness */
  color: white; /* White font color */
  font-style: bold; /* bold style */
  line-height: 1.5; /* Adjust line height for spacing between lines */
  margin: 0; /* Remove default margins */
  white-space: pre-wrap; /* Preserve new lines */
}


.blur {
  filter: blur(5px);
}
