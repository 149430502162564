.generate-coupon-page {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  .form {
    margin: 20px 0;
  }
  
  .form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .form input {
    padding: 10px;
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form button:hover {
    background-color: #0056b3;
  }
  
  .generated-coupon {
    margin-top: 20px;
    font-size: 1.2em;
    color: green;
  }
  
  .add-manual-coupon {
    margin: 30px 0;
  }
  
  .add-manual-coupon input {
    padding: 10px;
    width: 70%;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-manual-coupon button {
    padding: 10px 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-manual-coupon button:hover {
    background-color: #218838;
  }
  
  .success-message {
    margin-top: 20px;
    color: green;
    font-weight: bold;
  }
  
  .error-message {
    margin-top: 20px;
    color: red;
    font-weight: bold;
  }
  