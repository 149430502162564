.quiz-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  position: relative;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding-top: 80px; /* Add space for fixed logo and dots */
}

/* Fix the position of the Logo */
.quiz-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000; /* Ensure it's above other content */
}

/* Logo style */
.quiz-logo {
  width: 100px;
  margin-bottom: 10px;
}

/* Fix the dots' position below the logo */
.dot-container {
  display: flex;
  justify-content: left;
  position: fixed;
  top: 60px; /* Position it below the logo */
  left: 18px;
  z-index: 1000; /* Ensure it's above other content */
}

/* Dot styles */
.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 4px;
}

.dot.unattempted {
  background-color: gray;
}

.dot.correct {
  background-color: green;
}

.dot.incorrect {
  background-color: red;
}

/* Timer Bar */
.quiz-timer {
  height: 5px;
  background-color: #87ceeb;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: width 0.1s linear;
  z-index: 1000;
}

/* Question Text */
.quiz-question {
  font-size: 20px;
  font-weight: bold;
  text-align: justify;
  color: #333;
  margin-top: 10px; /* Ensure space for the fixed logo and dots */
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 1.5;
  word-wrap: break-word; /* Prevent text overflow */
}

/* Image inside question */
.question-image {
  max-width: 100%;       /* Ensure the image doesn't exceed the container width */
  height: auto;          /* Maintain the aspect ratio */
  object-fit: contain;   /* Ensures the image fits within the container without distortion */
  margin: 10px auto;     /* Center the image and add spacing */
  display: block;        /* Block display to make it responsive */
  cursor: pointer;      /* Make image clickable */
}

/* Options - Displayed at the Bottom */
.quiz-options {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  z-index: 1100;
  padding-bottom: 5px;
}

/* Option Buttons */
.quiz-option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 25px 25px; /* Increased top and bottom padding */
  margin: 3px 0;
  border: 1px;
  border-radius: 2px; /* Rectangular buttons */
  cursor: pointer;
  width: 80%; /* Responsive width */
  max-width: 500px;
  background-color: white;
  font-size: 16px;
  color: black;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow for aesthetics */
  transition: background-color 0.3s, color 0.3s;
}

/* Option Label (A, B, C, D) */
.option-label {
  font-weight: bold;
  margin-right: 10px;
  color: #555;
}

.quiz-option.correct .option-label,
.quiz-option.incorrect .option-label {
  color: white; /* Change label color to white for correct/incorrect options */
}

.quiz-option.correct {
  background-color: #60db92;
  color: white;
}

.quiz-option.incorrect {
  background-color: #f77c7c;
  color: white;
}

.quiz-option:disabled {
  cursor: not-allowed;
}

.quiz-option:hover:not(:disabled) {
  background-color: #f0f0f0;
}

/* Close Icon */
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #555;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1100;
}

.close-icon:hover {
  color: black;
}

/* Exit Modal */
.exit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.exit-modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.exit-modal h2 {
  margin-bottom: 20px;
  font-size: 18px;
}

.exit-modal-buttons {
  display: flex;
  justify-content: space-around;
}

.exit-modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.exit-modal-buttons button:first-child {
  background-color: #f0f0f0;
  color: black;
}

.exit-modal-buttons button:first-child:hover {
  background-color: #e0e0e0;
}

.exit-modal-buttons button:last-child {
  background-color: red;
  color: white;
}

.exit-modal-buttons button:last-child:hover {
  background-color: darkred;
}

/* Image Overlay */
.q-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}

.overlay-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.close-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.close-overlay:hover {
  color: #ddd;
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
  .quiz-banner {
    top: 10px;
    left: 10px;
  }

  .quiz-logo {
    width: 80px;
  }

  .quiz-option {
    width: 90%; /* Adjust to be responsive */
  }

  .quiz-question {
    font-size: 18px; /* Adjust font size for smaller screens */
    margin-top: 30px; /* Adjust for fixed elements */
  }

  .question-image {
    max-height: 150px; /* Limit the image height for smaller screens */
  }
}

@media (max-width: 480px) {
  .quiz-banner {
    top: 10px;
    left: 10px;
  }

  .quiz-logo {
    width: 70px;
  }

  .quiz-option {
    width: 95%; /* Adjust to be responsive */
  }

  .quiz-question {
    font-size: 16px; /* Further adjust font size for very small screens */
    margin-top: 30px; /* Adjust for fixed elements */
  }

  .question-image {
    max-height: 120px; /* Limit the image height even further */
  }
}

/* Loading and Quiz Complete Screens */
.loading-screen, .quiz-complete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
}

/* Rotating Circle */
.rotating-circle {
  width: 80px;
  height: 80px;
  border: 6px solid transparent;
  border-top: 6px solid #2c918e; /* The rotating color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-top: 20px; /* Space below the text */
}

/* Rotating Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
