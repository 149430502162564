/* Scoped RegisterPage Styles */
body.register-page {
    background: linear-gradient(135deg, #74ebd5, #9face6);
    font-family: 'Arial', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.register-container {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    max-width: 450px;
    transition: all 0.3s ease-in-out;
}

.register-container h1,
.register-container h2 {
    color: #333;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
}

.register-container .input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
}

.register-container .input-wrapper .icon {
    position: absolute;
    left: 10px;
    color: #888;
    font-size: 1.2rem;
}

.register-container .input-wrapper input,
.register-container .input-wrapper select {
    width: 100%;
    padding: 0.8rem 0.8rem 0.8rem 2.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

.register-container .input-wrapper input:focus,
.register-container .input-wrapper select:focus {
    border-color: #74ebd5;
    box-shadow: 0 0 5px rgba(116, 235, 213, 0.5);
}

.register-container button {
    width: 100%;
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    background: linear-gradient(135deg, #74ebd5, #9face6);
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.register-container button:hover {
    background: linear-gradient(135deg, #9face6, #74ebd5);
}

.register-container button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.register-container .error {
    color: #ff4d4d;
    font-size: 0.9rem;
    text-align: center;
    margin-top: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .register-container {
        padding: 1.5rem;
        max-width: 90%;
    }

    .register-container h1,
    .register-container h2 {
        font-size: 1.5rem;
    }

    .register-container .input-wrapper input,
    .register-container .input-wrapper select {
        font-size: 0.9rem;
        padding: 0.7rem 0.7rem 0.7rem 2rem;
    }

    .register-container button {
        font-size: 0.9rem;
        padding: 0.7rem;
    }
}

@media (max-width: 480px) {
    .register-container {
        padding: 1rem;
    }

    .register-container h1,
    .register-container h2 {
        font-size: 1.3rem;
    }

    .register-container .input-wrapper input,
    .register-container .input-wrapper select {
        font-size: 0.85rem;
        padding: 0.6rem 0.6rem 0.6rem 1.8rem;
    }

    .register-container button {
        font-size: 0.85rem;
        padding: 0.6rem;
    }
}
