.manage-subtopics-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #444;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

select,
input[type="text"] {
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  margin-top: 5px;
}

.subtopic-list {
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

.subtopic-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.subtopic-item button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.subtopic-item button:first-of-type {
  background: #007bff;
}

.subtopic-item button:last-of-type {
  background: #dc3545;
}

.add-subtopic {
  display: flex;
  gap: 10px;
}

.add-subtopic input {
  flex-grow: 1;
}

.add-subtopic button {
  padding: 10px;
  color: #fff;
  background: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .subtopic-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .subtopic-item button {
    margin-left: 0;
    margin-top: 5px;
  }
}
