/* Topbar Container */
.topbar {
  width: 100%;
  background-color: skyblue;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the menu horizontally */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed; /* Make the topbar fixed */
  top: 0; /* Align it to the top of the page */
  left: 0; /* Ensure it spans the full width */
  z-index: 1000; /* Ensure it's above other elements */
  height: 1.5cm; /* Set the height of the topbar */
  box-sizing: border-box; /* Ensure padding doesn't affect the height */
}

/* Menu Buttons (Default Layout for Desktop) */
.menu {
  display: flex;
  gap: 20px;
  align-items: center;
  transition: all 0.3s ease;
}

/* Individual Button Styling */
.menu button {
  background-color: transparent; /* Make background transparent */
  color: white; /* Set text color to white */
  border: 1px solid rgba(255, 255, 255, 0.6); /* Light white border for visibility */
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold; /* Make text bold */
  transition: background-color 0.3s, font-size 0.3s, color 0.3s;
}

.menu button:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Light white tint on hover */
  color: white; /* Keep text color white */
}

/* Toggle Button */
.menu-toggle {
  display: none;
  background-color: transparent; /* Make background transparent */
  color: white; /* Set text color to white */
  border: none; /* Light white border for visibility */
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  left: 10px; /* Align the toggle button to the left */
  font-size: 1em; /* Ensure consistent text size */
  font-weight: bold; /* Make text bold */
  transition: background-color 0.3s, color 0.3s;
}

.menu-toggle:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Light white tint on hover */
  color: white; /* Keep text color white */
}

/* For Mobile and Tablets */
@media (max-width: 768px) {
  /* Show the toggle button on smaller screens */
  .menu-toggle {
    display: block;
  }

  /* Adjust menu layout for small screens */
  .menu {
    position: absolute;
    top: 50%;
    left: 50px; /* Position buttons to the right of the toggle button */
    transform: translateY(-50%);
    flex-direction: row; /* Horizontal layout for buttons */
    gap: 10px; /* Smaller gap for mobile */
    background-color: transparent; /* Transparent background for menu */
    visibility: hidden; /* Hidden by default */
    opacity: 0; /* Invisible by default */
    transition: all 0.3s ease-in-out;
  }

  .menu.open {
    visibility: visible; /* Show the buttons when menu is open */
    opacity: 1; /* Fully visible */
  }

  /* Adjust button sizes for smaller screens */
  .menu button {
    padding: 5px 10px;
    font-size: 0.8em; /* Smaller text size */
  }
}
