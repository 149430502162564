.bookmark-page {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.bookmark-page h1 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.loading,
.error-message {
  font-size: 1.5em;
  color: #555;
  text-align: center;
  margin-top: 50px;
}

.bookmark-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.bookmark-card {
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px; /* Limit the width for consistent appearance */
  transition: transform 0.2s, box-shadow 0.2s;
}

.bookmark-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.bookmark-card h3 {
  margin: 0;
  font-size: 1.4em;
  color: #444;
}

.options p {
  margin: 5px 0;
  color: #555;
  font-size: 1em;
}

.bookmark-meta {
  margin-top: 15px;
  font-size: 0.9em;
  color: #888;
  font-style: italic;
}

/* Responsive Design */
@media (max-width: 768px) {
  .bookmark-card {
    max-width: 90%; /* Make the cards larger on smaller devices */
    margin: 0 auto; /* Center cards */
  }
}

@media (max-width: 480px) {
  .bookmark-page h1 {
    font-size: 1.5em; /* Adjust title size */
  }

  .bookmark-card {
    padding: 15px; /* Reduce padding on smaller screens */
  }

  .bookmark-card h3 {
    font-size: 1.2em; /* Adjust heading size */
  }

  .options p {
    font-size: 0.9em; /* Reduce option text size */
  }
}
