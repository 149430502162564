/* OfferPage.css */
.offerpage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.coming-soon {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  padding: 20px;
  background-color: #4a90e2;
  color: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
