.qbank-page {
  display: flex;
  transition: margin-left 0.3s;
  padding: 2rem 1rem 3rem;
  margin-left: 50px; 
}

.qbank-content {
  margin-top: 20px;
  width: calc(100% - 250px);
  padding: 20px 0;
  margin-left: 50px; 
}

.qbank-header {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

/* Style for the top 3 cards */
.top-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  margin-bottom: 2rem;
  padding: 10px 0;
}

.top-card {
  background-color: skyblue;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.top-card h3 {
  margin: 0;
  font-size: 1rem;
  color: white;
}

.top-card p {
  font-size: 0.9rem;
  color: white;
}

.top-card:hover {
  background-color: #87cefa;
  transform: translateY(-5px);
}

.icon-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.bookmark-icon {
  width: 15px;
  height: 15px;
}

.subject-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
}

.subject-card {
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.subject-card:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}

/* Adjustments for mobile screens (max-width: 768px) */
@media (max-width: 768px) {
  .qbank-page {
    margin-left: 0;
  }
  .qbank-content {
    width: 100%;
    margin-left: 0;
  }
  .top-card {
    padding: 8px;
  }
  .top-card h3 {
    font-size: 0.9rem;
  }
  .top-card p {
    font-size: 0.8rem;
  }
}

/* Adjustments for very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .top-card {
    padding: 5px;
  }
  .top-card h3 {
    font-size: 0.8rem;
  }
  .top-card p {
    font-size: 0.7rem;
  }
  .subject-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .subject-card {
    padding: 15px;
  }
}
