.side-panel {
  height: 100%; /* Ensure it covers the full height */
  overflow-y: auto; /* Scrollable if content overflows */
  background-color: #2c3e50; /* Dark background */
  color: #ecf0f1; /* Light text color */
  padding: 20px;
  border-right: 1px solid #bdc3c7;
  position: relative; /* Necessary for contained positioning */
}

.side-panel h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
  text-align: center; /* Center-align the header */
}

.side-panel ul {
  list-style: none;
  padding: 0;
}

.side-panel li {
  padding: 10px 15px;
  margin: 5px 0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.side-panel li:hover {
  background-color: #34495e;
}

.logout-button {
  cursor: pointer;
  color: #f44336; /* Red color for logout */
  font-weight: bold;
  padding: 10px;
  text-align: center; /* Center-align logout text */
}

.logout-button:hover {
  background-color: #ffebee;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .side-panel {
    position: absolute; /* Make the panel overlay the main content */
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    transform: translateX(-100%); /* Hidden by default */
    transition: transform 0.3s ease; /* Smooth transition for sliding */
    z-index: 50; /* Ensure it stays above other content */
  }

  .side-panel.visible {
    transform: translateX(0); /* Slide in when visible */
  }

  .side-panel.hidden {
    transform: translateX(-100%); /* Slide out when hidden */
  }
}
