/* General styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  background-color: #f4f7fc;
}

/* Container for both side panel and content */
.wallet-page-container {
  display: flex;
  position: relative;
  padding-top: 2rem;
}

/* The main content area */
.wallet-page-content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 100px; /* Space for the side panel */
  transition: margin-left 0.3s ease;
}

/* Adjust content when side panel is collapsed */
.sidepanel.collapsed + .wallet-page-content {
  margin-left: 4cm;
}

.wallet-header {
  text-align: center;
  margin-bottom: 20px;
}

.wallet-header h1 {
  font-size: 2.5em;
  color: #333;
}

.wallet-info, .coupon-section {
  margin: 20px 0;
}

.wallet-info h4 {
  font-size: 1.2em;
  color: #555;
  font-weight: bold;
  text-align: center;
}

.coupon-section h5 {
  font-size: 1.2em;
  color: #555;
  font-weight: bold;
  text-align: center;
}

/* Coupon section */
.coupon-input-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.coupon-input {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 60%;
}

.action-button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #0056b3;
}

.secondary-button {
  background-color: #28a745;
}

.secondary-button:hover {
  background-color: #218838;
}

/* Error and Message Styles */
.error-message {
  color: red;
  font-size: 1.1em;
  text-align: center;
  margin-top: 20px;
}

.message {
  font-size: 1.1em;
  color: #28a745;
  text-align: center;
  margin-top: 20px;
}

.loading-message {
  font-size: 1.2em;
  color: #888;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .wallet-page-content {
    margin-left: 0; /* No margin on mobile */
    padding: 10px;
  }

  .sidepanel {
    width: 4cm;
  }

  .sidepanel.collapsed + .wallet-page-content {
    margin-left: 0;
  }
}
