/* Admin Page Layout */
.admin-page {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  position: relative; /* Ensure child elements with 'absolute' positioning align relative to this */
}

.main-panel {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

/* Profile Section */
.profile-section {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 10; /* Ensure it's above other elements */
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #ddd;
  transition: transform 0.2s, border-color 0.2s;
}

.profile-image:hover {
  transform: scale(1.1);
  border-color: #007bff;
}

.logout-button {
  display: inline-block; /* Show the button */
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.logout-button:hover {
  background-color: #ff1a1a;
}

/* Mobile Top Admin Bar */
.top-admin-bar {
  display: none; /* Hide by default */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  text-align: center;
  z-index: 10;
  width: 100%;
}

.top-admin-bar .toggle-arrow {
  font-size: 30px;
  cursor: pointer;
  margin: 0 auto; /* Center the icon horizontally */
  display: block; /* Ensures the icon takes up space and is centered */
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .top-admin-bar {
    display: block; /* Show the top admin bar on mobile */
  }

  .admin-page {
    flex-direction: column; /* Stack content vertically on mobile */
  }

  .main-panel {
    margin-top: 50px; /* Ensure main content doesn't overlap the top admin bar */
  }

  /* Full-page dropdown (only visible on mobile devices) */
  .dropdown-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .dropdown-content button {
    display: block;
    margin: 10px 0;
    padding: 10px;
    font-size: 18px;
    background-color: #f4f4f4;
    border: none;
    width: 200px;
    cursor: pointer;
  }

  .dropdown-content button:hover {
    background-color: #ddd;
  }
}
