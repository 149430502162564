/* Full-page styling */
.leaderboard-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f4f7fc; /* Light grey background */
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
}

/* Leaderboard Title */
.leaderboard-title {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

/* Current User's Rank Display */
.current-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.current-user .rank {
  background-color: #0288d1; /* Blue background for rank */
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
  width: fit-content;
}

/* Leaderboard Entries */
.leaderboard {
  width: 100%;
  max-width: 1200px; /* Ensures leaderboard doesn't get too wide on large screens */
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px; /* Extra space at the bottom for mobile screens */
}

.leaderboard-entry {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px; /* Adjust width for better readability */
  text-align: center;
  cursor: pointer; /* Pointer cursor to indicate clickability */
  transition: all 0.3s ease; /* Smooth transition for hover and active effects */
}

.leaderboard-entry .rank {
  background-color: #fff; /* Green background for rank */
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.leaderboard-entry .username {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize; /* Capitalize first letter of username */
}

/* Highlight current user's entry */
.current-user-entry {
  background-color: #3ba9b3; /* Yellow highlight for current user */
  border-left: 5px solid #d63a59; /* Left border to accentuate */
}

/* Hover Effect on Leaderboard Entry */
.leaderboard-entry:hover {
  transform: translateY(-5px); /* Slight upward movement */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

/* Click (Active) Effect */
.leaderboard-entry:active {
  transform: scale(0.97); /* Slight scale-down effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Reduced shadow on click */
}

/* Responsive Design */

/* For large screens like PC */
@media (min-width: 1200px) {
  .leaderboard-entry .rank {
    font-size: 22px;
  }

  .leaderboard-entry .username {
    font-size: 20px;
  }

  .leaderboard-title {
    font-size: 40px;
  }
}

/* For tablet screens like iPads */
@media (min-width: 768px) and (max-width: 1199px) {
  .leaderboard-title {
    font-size: 32px;
  }

  .leaderboard-entry {
    align-items: center;
    text-align: left;
    width: 100%;
    max-width: 700px; /* Adjust width for tablets */
  }

  .leaderboard-entry .rank {
    font-size: 20px;
    width: 35px;
    height: 35px;
  }

  .leaderboard-entry .username {
    font-size: 18px;
  }

  .current-user .rank {
    font-size: 18px;
  }
  .current-user-entry .username {
    color: #fff;
  }
}

/* For mobile screens */
@media (max-width: 767px) {
  .leaderboard-title {
    font-size: 28px;
  }

  .leaderboard-entry {
    align-items: center;
    width: 100%;
    padding: 10px;
    max-width: 100%;
  }

  .leaderboard-entry .rank {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .leaderboard-entry .username {
    font-size: 16px;
  }

  .current-user .rank {
    font-size: 16px;
  }
  .current-user-entry .username {
    color: #fff;
  }
}
