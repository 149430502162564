/* Layout Integration */
.manage-daily-mcq-container {
  display: flex;
  flex-direction: column;
  background-color: #ecf0f1;
  color: #2c3e50;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Form Styling */
.manage-daily-mcq-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.manage-daily-mcq-form .form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.manage-daily-mcq-form label {
  font-weight: bold;
  font-size: 1em;
  color: #34495e;
}

.manage-daily-mcq-form textarea,
.manage-daily-mcq-form input[type="text"] {
  padding: 10px;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  font-size: 1em;
  resize: vertical;
}

.manage-daily-mcq-form input[type="file"] {
  padding: 5px;
  font-size: 1em;
}

.manage-daily-mcq-form .image-preview {
  margin-top: 10px;
  max-width: 100%;
  border-radius: 5px;
}

/* Option Styling */
.option-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.option-group input[type="text"] {
  flex: 1;
}

.option-group input[type="radio"] {
  cursor: pointer;
}

.option-group .remove-option-btn {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.option-group .remove-option-btn:hover {
  background-color: #c0392b;
}

.add-option-btn {
  background-color: #27ae60;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-option-btn:hover {
  background-color: #219150;
}

/* Submit Button */
.submit-btn {
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #2980b9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .manage-daily-mcq-container {
    padding: 10px;
  }

  .manage-daily-mcq-form textarea,
  .manage-daily-mcq-form input[type="text"] {
    font-size: 0.9em;
    padding: 8px;
  }

  .option-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .option-group input[type="text"] {
    width: 100%;
  }

  .option-group .remove-option-btn {
    align-self: flex-end;
    margin-top: 5px;
  }

  .add-option-btn {
    width: 100%;
    text-align: center;
  }

  .submit-btn {
    width: 100%;
    text-align: center;
  }
}
