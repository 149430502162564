/* Dashboard Page */
.dashboard-page {
  position: relative;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: hidden; /* Prevent scrolling behind the content */
  padding-top: 3rem;
  box-sizing: border-box;
}

.banner .banner-image {
  width: 100%;
  object-fit: cover;
  display: flex;
  height: 4cm;
  justify-content: center;
}

/* Dashboard Content */
.dashboard-content {
  margin: 0;
  padding: 20px;
  width: 100%; /* Full width */
  height: calc(100% - 4cm); /* Adjust for banner height */
  overflow-y: auto; /* Enable scrolling if content overflows */
  box-sizing: border-box;
}

/* Daily MCQ Card */
.daily-mcq-card {
  background-color: #f9f9f9;
  padding: 2px 2px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 900px; /* Prevent the card from getting too wide */
  box-sizing: border-box; /* Prevent padding from affecting overall width */
  margin-left: auto;
  margin-bottom: 20px;
  margin-right: auto; /* Center the card horizontally */
}

.daily-mcq-card h3 {
  font-size: 1.5em;
  color: #333;
}

.daily-mcq-card .question {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 10px;
  text-align: center; /* Center align question text */
}

.daily-mcq-card .mcq-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 10px auto; /* Center image horizontally */
  border-radius: 8px;
}

.daily-mcq-card .options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center; /* Center align options */
}

.daily-mcq-card button {
  padding: 10px;
  background-color: #f1f1f1;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 1em;
  width: 100%; /* Full-width buttons */
  transition: background-color 0.3s;
}

.daily-mcq-card button:hover {
  background-color: #e0e0e0;
}

/* Marking selected options */
.daily-mcq-card button.selected {
  background-color: #d3d3d3; /* Light gray for selected option */
}

/* Correct and Incorrect answers */
.daily-mcq-card button.correct {
  background-color: #4caf50; /* Green for correct answer */
  color: white;
}

.daily-mcq-card button.incorrect {
  background-color: #f44336; /* Red for incorrect answer */
  color: white;
}

.daily-mcq-card .answer-explanation {
  margin-top: 10px;
  font-size: 1em;
  color: #555;
}

/* Download Section */
.download-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 35px;
  flex-wrap: wrap;
  gap: 20px;
}

.download-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.download-card:hover {
  transform: scale(1.05);
}

.download-card a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
}

.download-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.download-text {
  color: #007bff;
  font-weight: bold;
}

/* Responsive Adjustments */

/* For tablets and smaller screens (max-width: 768px) */
@media (max-width: 768px) {
  .dashboard-content {
    padding: 10px; /* Adjust padding */
  }

  .banner-image {
    max-height: 3cm; /* Reduce max height for smaller screens */
  }

  .daily-mcq-card {
    padding: 15px; /* Adjust padding for smaller devices */
    margin-left: 5%; /* Add a little margin on smaller devices */
    margin-right: 5%; /* Add a little margin on smaller devices */
  }

  .daily-mcq-card h3 {
    font-size: 1.3em; /* Adjust header size for smaller screens */
  }

  .daily-mcq-card .question {
    font-size: 1.1em; /* Adjust question text size */
  }

  .daily-mcq-card button {
    font-size: 0.9em; /* Adjust button text size for smaller screens */
  }

  .download-card {
    width: 150px;
    padding: 15px;
  }

  .download-icon {
    width: 60px;
    height: 60px;
  }

  .download-text {
    font-size: 0.9em;
  }
}

/* For very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .download-section {
    flex-direction: row;
    gap: 1px;
  }

  .download-card {
    width: 130px;
    padding: 10px;
  }

  .download-icon {
    width: 50px;
    height: 50px;
  }

  .download-text {
    font-size: 0.8em;
  }
}
