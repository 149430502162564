.make-ios-app {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.step {
  margin: 20px 0;
}

.step h2 {
  font-size: 1.5rem;
  color: #555;
}

.step-image {
  width: 80%;
  max-width: 600px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.step p {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}
