/* Main container for the SubtopicsPage */
.subtopics-page {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 5rem; /* Remove right and left padding */
  transition: margin-left 0.3s;
}

.subtopics-header {
  text-align: center;
  margin-bottom: 2rem;
}

.subtopics-header h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
  padding: 1.5rem 0 0.5rem;
}

.subtopics-header p {
  color: #555;
  font-size: 1rem;
  margin: 0.5rem 0;
}

.subtopics-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); /* Dynamic grid layout */
  gap: 20px; /* Spacing between cards */
  padding: 0 1rem 0;
}

.subtopic-card {
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  text-align: center;
}

.subtopic-card:hover {
  background-color: #e0e0e0;
  transform: translateY(-5px); /* Slight lift on hover */
}

.subtopic-card h3 {
  margin: 0 0 10px;
  color: #333;
}

.subtopic-card p {
  margin: 0;
  color: #666;
}

.loading-text {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
}

/* Wallet Balance Styling */
.subtopics-header strong {
  color: #007bff; /* Highlight wallet balance text */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .subtopics-page {
    margin-left: 0; /* Remove left margin */
    padding: 1.5rem 0; /* Reduce padding for smaller screens */
  }
  
  .subtopics-header h1 {
    font-size: 2rem;
  }
  
  .subtopics-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust for smaller cards */
    gap: 15px; /* Reduce gap */
  }

  .subtopic-card {
    padding: 15px; /* Smaller padding */
  }
}

/* Very small screens (mobile view) */
@media (max-width: 480px) {
  .subtopics-header h1 {
    font-size: 1.8rem;
  }

  .subtopics-container {
    grid-template-columns: repeat(2, 1fr); /* Two cards per row */
    gap: 10px; /* Reduced gap */
  }

  .subtopic-card {
    padding: 10px; /* Compact design for mobile */
  }
}
