.manage-subjects-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  color: #333;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.add-subject-form {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.add-subject-form input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.add-subject-form button {
  padding: 10px 15px;
  font-size: 14px;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.add-subject-form button:hover {
  background: #0056b3;
}

.subjects-table {
  width: 100%;
  border-collapse: collapse;
}

.subjects-table th,
.subjects-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.subjects-table th {
  background: #f1f1f1;
  font-weight: bold;
}

.subjects-table td input {
  padding: 5px;
  font-size: 14px;
}

.subjects-table button {
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.subjects-table button:hover {
  background: #0056b3;
}

.subjects-table button:last-child {
  background: #dc3545;
}

.subjects-table button:last-child:hover {
  background: #a71d2a;
}
